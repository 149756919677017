import React, { useState } from "react";



function MP_Lobby({ lobbyCode, changeName, name, startGame, isAdmin, Lround, changeRound}) {
  const [values, setValues] = useState({ playername: name, rounds: Lround }); // State to store lobby code input


  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
      //console.log(value)
    }
  };


  return (
    <div>
      <div>
        <h5 className="card-title">Lobby</h5>
        <div className="form-group row">
          <div className="col">
            <label htmlFor="lobbyName">Spielername:</label>
            <div className="d-flex align-items-center">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  changeName(values.playername);
                }} className="d-flex align-items-center">
                <input type="text" onChange={set('playername')} className="input-SQ rounded form-control" id="lobbyName" value={values.playername} />
                <button type="button" onClick={() => changeName(values.playername)} className="btn text-white color-lightblue ml-2">Ändern</button> {/* Füge margin hinzu */}
              </form>
            </div>
          </div>
          <div className="col">
            <label htmlFor="lobbyCode">Invitecode:</label>
            <input type="text" className="input-SQ rounded form-control" id="lobbyCode" defaultValue={lobbyCode} readOnly />
          </div>
        </div>
      </div>
      <div className="form-group row">
      {isAdmin ?
        <div className="form-group col">
          <label htmlFor="rounds">Anzahl der Runden:</label>
          <div className="d-flex align-items-center">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                changeRound(values.rounds);
              }} className="d-flex align-items-center">
              <input type="number" onChange={set('rounds')} className="input-SQ rounded form-control" id="lobbyName" value={values.rounds} />
              <button type="button" onClick={() => changeRound(values.rounds)} className="btn text-white color-lightblue ml-2">Ändern</button> {/* Füge margin hinzu */}
            </form>
          </div>
        </div>
        : ""}
      {isAdmin ?
        <div className="col">
          <label htmlFor="rounds">Admin Hinweis:</label>
          <div className="d-flex align-items-center">
            Du kannst im Chat über "/" bestimmte Befehle ausführen.
            Sollte der Admin die Runde verlassen wird automatisch ein neuer Admin bestimmt.
          </div>
        </div>
        : ""}
        </div>
      {isAdmin ?
        <button type="button" onClick={() => startGame()} className="btn text-white color-lightblue mr-2">Starten</button>
        : ""}
    </div>
  );
}





export default MP_Lobby;