import React, { useEffect, useState, useRef } from "react";
import GameModule from "./game";
import ScoreModule from "./score";
import { useCookies } from 'react-cookie';
import { CheckAnswer, GetSongs } from "../../../functions/fnc_singleplayer";
import Result from "./result";


function SinglePlayer() {
    const [cookies, setCookie] = useCookies(['song-quiz']);
    const [data, setData] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [songs, setSongs] = useState([0]);
    const [score, setScore] = useState(0);
    const [highscore, setHighscore] = useState(0);
    const [seen, setSeen] = useState(false);
    const [volume, setVolume] = useState(0.10);

    const audioRef = useRef(null); // Audio-Referenz

    useEffect(() => {
        if (cookies.SQH) setHighscore(cookies.SQH);

        if (audioRef.current) {
            audioRef.current.volume = 0.10;
        }

        GetSongs(songs).then((data) => {
            setData(data);
        });

        if (highscore < score) {
            setHighscore(score);
            const date = new Date();
            date.setFullYear(date.getFullYear() + 100);
            setCookie('SQH', score, { path: '/', expires: date });
        }
        // eslint-disable-next-line
    }, [score, setCookie, songs]);

    // Audio-Steuerung
    const broke = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const start = () => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
            if (!audioRef.current.paused) {
                audioRef.current.pause();
            }
            audioRef.current.play();
        }
    };

    const changeVolume = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);

        if (audioRef.current) {
            audioRef.current.volume = newVolume;
        }
    };

    // click-Funktion
    const click = (name) => {
        setSongs((prevSongs) => [...prevSongs, data.answer.id]);
        broke();
        const values = { id: data.answer.id, select: name };

        CheckAnswer(values)
            .then((p_data) => {
                setAnswer(p_data);
                setSeen(true);
                if (p_data.right) {
                    setScore(score + 1);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // Nächste Runde
    const next = () => {
        setData(null);

        if (answer?.right) {
            GetSongs(songs).then((data) => {
                setData(data);
                setSeen(false);
                if (audioRef.current) {
                    audioRef.current.volume = volume;
                }

            });
        } else {
            setScore(0);
            setSongs([0]);
            GetSongs(songs).then((data) => {
                setData(data);
                setSeen(false);
                if (audioRef.current) {
                    audioRef.current.volume = volume;
                }

            });
        }
    };

    const GameModul = () => {
        if (data === null) return <div>LOAD</div>;
        if (!seen) return <GameModule data={data} start={start} click={click} />;
        if (seen) return <Result next={next} data={answer} />;
    };

    return (
        <div>
            <div className="card card-dark color-darkblue text-white middle w-75 mx-auto">
                <div className="card-body container">
                    <ScoreModule score={score} hscore={highscore} vol={volume} changeVol={changeVolume} />
                    {!seen && data && data.answer && data.answer.url && (
                        <audio id="media" autoPlay={true} ref={audioRef} src={data.answer.url} onLoadedMetadata={() => {
                            if (audioRef.current) {
                                audioRef.current.volume = volume; // Lautstärke sicherheitshalber erneut setzen
                            }
                        }}></audio>
                    )}
                    <GameModul />
                </div>
            </div>
        </div>
    );
}

export default SinglePlayer;
