import React, { useEffect, useState } from "react";
import { VscServer } from "react-icons/vsc";
import fetchURL from "../../Config";

function Changelog() {
    const [data, setData] = useState(null);

    const getData = () =>
        fetch(`${fetchURL}/changelog/list`)
            .then((res) => res.json())

    useEffect(() => {
        getData().then((data) => setData(data))

    }, [])

    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('\n').map((str, i) => <div key={i}>{str}<br /></div>);

        return newText;
    }
    
    return (<div style={{ marginTop: '5rem' }} className=" w-75 mx-auto">
        <div id="accordion" role="tablist">
            {data?.map((item) =>
                <div key={item.id}>
                    <div className="card  text-white color-darkblue">
                        <div className="card-header card-daark" role="tab" id={`heading${item.id}`}>
                            <h5 className="mb-0 row " data-toggle="collapse" href={`#collapse${item.id}`}  aria-controls={`collapse${item.id}`}>
                                <p className="text-white col">
                                    {item.title}
                                </p>
                                <VscServer className="col-auto" />
                            </h5>
                        </div>
                        <div id={`collapse${item.id}`} className="collapse " role="tabpanel" aria-labelledby={`heading${item.id}`} data-parent="#accordion">
                            <div className="card-body">
                                <div key={item.id} className="card-text">
                                    <NewlineText text={item.content} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            )}
        </div>
        <div style={{ marginTop: '5rem' }}></div>
    </div>)

}

export default Changelog;