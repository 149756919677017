import React from "react";


function ScoreModule(props) {

    return (
        <div className="row justify-content-center mt-5 text-white mx-auto " >
            <div className="col-md-4 text-center">
                <div className="card color-lightblue w-100 d-flex">
                    <div><span className="small ">HighScore: </span> <strong className="badge color-darkblue score">{props.hscore}</strong></div>
                </div>
            </div>
            <div className="col-md-4 text-center w-100">
                <div>
                    <label htmlFor="volumeSlider" style={{ marginRight: "10px" }}>Lautstärke:</label>
                    <input
                        id="volumeSlider"
                        type="range"
                        min="0"
                        max="0.30"
                        step="0.01"
                        value={props.vol}
                        className="slider-SQ"
                        onChange={props.changeVol}
                    />
                </div>
            </div>
            <div className="col-md-4 text-center ">
                <div className="card color-lightblue w-100 d-flex">
                    <div><span className="small ">Score: </span> <strong className="badge color-darkblue score">{props.score}</strong></div>
                </div>
            </div>
        </div>)
}

export default ScoreModule;