import React from "react";


function Leaderboard({ players, currentPlayerName }) {
    // Sort players by score in descending order
    const sortedPlayers = [...players].sort((a, b) => b.score - a.score);

    return (
        <div>
            <h5 className="card-title">Leaderboard</h5>
            <ol className="list-group" style={{ maxHeight: "400px", minHeight: "400px", overflowY: "auto" }}>
                {sortedPlayers.map((player, index) => (
                    <li
                        key={index}
                        className={`list-group-item ${index % 2 === 0 ? "color-lightblue" : "color-blue"} d-flex justify-content-between align-items-center ${player.name === currentPlayerName ? "highlight" : ""}`}
                    >
                        {player.name}
                        <span className="badge badge-dark">{player.score}</span>
                    </li>
                ))}
            </ol>
        </div>
    );
}

export default Leaderboard;