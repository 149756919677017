import React, { useState } from "react";
import { RiShieldCheckFill } from "react-icons/ri";
import fetchURL from "../../Config";

function Proposals() {
    const [values, setValues] = useState({ title: '', type: 'bug', content: '' });
    const [finish, setFinish] = useState(false);

    const handleChange = name => ({ target: { value } }) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = async () => {
        if (Object.values(values).some(value => !value.trim())) {
            alert("Please fill in all fields!");
            return;
        }

        try {
            const response = await fetch(`${fetchURL}/proposals/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            const data = await response.json();
            console.log('Success:', data);
            setFinish(true);
            setValues({ title: '', type: 'bug', content: '' });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <div className="card card-dark color-darkblue text-white w-50 middle ">
                <div className="card-body text-center ">
                    {finish ? (
                        <>
                            <h2 className="card-title ">Danke für deine Hilfe!</h2>
                            <hr className="bg-white mb-4" />
                            <div className="t-sm text-left">
                                <div className="w-75 col-centered text-center">
                                    <h6>Wir werden uns drum kümmern!</h6>
                                    <RiShieldCheckFill className="icon text-white" />
                                    <p className="mt-2 text-muted">Dein SongQuiz Team</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 className="card-title ">Bugs & Wünsche</h2>
                            <hr className="bg-white mb-4" />
                            <div className="t-sm text-left">
                                <div className="w-75 col-centered">
                                    <input
                                        className="input-SQ rounded form-control row mt-3  mx-auto"
                                        type="text"
                                        name="name"
                                        placeholder="Summary"
                                        onChange={handleChange('title')}
                                        value={values.title}
                                    />

                                    <select
                                        className=" selection-SQ rounded form-control row mt-3 mx-auto pr-5"
                                        name="mode"
                                        onChange={handleChange('type')}
                                        value={values.type}
                                    >
                                        <option value="bug">Bug</option>
                                        <option value="song">Song wunsch</option>
                                        <option value="text">Rechtschreibung</option>
                                        <option value="other">Sonstiges</option>
                                    </select>

                                    <textarea
                                        className="input-SQ rounded form-control  row mt-3  mx-auto"
                                        type="textarea"
                                        name="desc"
                                        cols="40"
                                        rows="5"
                                        placeholder="Beschreibe dein Anleigen"
                                        onChange={handleChange('content')}
                                        value={values.content}
                                    />

                                    <br />

                                    <button onClick={handleSubmit} className="btn text-white color-lightblue">Abschicken!</button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Proposals;
