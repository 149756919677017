import React, { useEffect, useState } from "react";
import fetchURL from "../../Config";

function Stats() {
    const [data, setData] = useState({ Songs: 0, Artists: 0, Genres: 0 });
    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);


    const getData = () =>
        fetch(`${fetchURL}/stats/`)
            .then((res) => res.json())

    useEffect(() => {
        getData().then((data) => setData(data))

    }, [])


    // Funktion zum Durchführen der Suche und Aktualisieren der Ergebnisse
    const handleSearch = () => {
        // Hier kannst du deine Suchlogik implementieren
        // Dummy-Daten für die Demonstration
        const dummyResults = ["Result 1", "Result 2", "Result 3"];
        setSearchResults(dummyResults);
    };

    return (
        <div style={{ marginTop: '5rem' }} className="container">
            <div className="row card card-dark color-darkblue text-white  w-75  mx-auto">
                <div className="card-body container">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                {/* Zusätzliche Card innerhalb der Haupt-Card */}
                                <div className="card card-dark color-lightblue text-white text-center mt-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Songs</h5>
                                        <p>{data.Songs}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                {/* Zusätzliche Card innerhalb der Haupt-Card */}
                                <div className="card card-dark color-lightblue text-white text-center mt-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Künstler</h5>
                                        <p>{data.Artists}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                {/* Zusätzliche Card innerhalb der Haupt-Card */}
                                <div className="card card-dark color-lightblue text-white text-center mt-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Genres</h5>
                                        <p>{data.Genres}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  {/* Such-Card 
            <div className="row card card-dark color-darkblue text-white mt-3 w-75 mx-auto">
                <div className="card-body text-center">
                    <h5 className="card-title">Song suchen</h5>
                    <div className="d-flex justify-content-center">
                        <input
                            type="text"
                            className="form-control input-SQ w-50"
                            placeholder="Suche nach ..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <div className="">
                            <button className="btn color-lightblue text-white ml-3" onClick={handleSearch}>
                                Suchen
                            </button>
                        </div>
                    </div>
                </div>
            </div>



            {/* Anzeige der Suchergebnisse 
            <div className="row card card-dark color-darkblue text-white  mt-3 w-75 mx-auto">
                <div className="card-body">
                    <h5 className="card-title"><u>Suchergebnisse</u></h5>
                    {searchResults.map((result, index) => (
                        <p key={index}>{result}</p>
                    ))}
                </div>
            </div>
            */}
        </div>
    );
}

export default Stats;
