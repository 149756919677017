import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Chat from "./chat";
import Leaderboard from "./leaderboard";
import MP_Select from "./lobby/select";
import { socket } from "../../../functions/fnc_socket";
import MP_Lobby from "./lobby/lobby";
import MP_Game from "./match/game";
import MP_Result from "./match/result";
import MP_Final from "./lobby/endScreen";

function MP_Index() {
  const Navigate = useNavigate();
  const [round, setRound] = useState({ round: 0, maxRound: 0 });
  const [songs, setSongs] = useState([]);
  const [isAdmin, setAdmin] = useState(false);
  const [lobbyCode, setLobbyCode] = useState("");
  const [mode, setMode] = useState("select");
  const [name, setName] = useState("");
  const [Presult, setPresult] = useState({});
  const [result, setResult] = useState({});
  const [Pcount, setPcount] = useState(0);
  const [messages, setMessages] = useState([]);
  const [players, setPlayers] = useState({});
  const [volume, setVolume] = useState(0.1); // Initial volume state
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    // Event Listeners setzen
    socket.on("playerUpdate", (updatedPlayers) => setPlayers(updatedPlayers));
    socket.on("chatUpdate", (chat) => setMessages(chat));
    socket.on("roundUpdate", (round) => setRound(round));
    socket.on("game", (response) => {
      if (response.success) {
        setSongs(response.data);
        setMode("play");
      } else {
        console.log(response.message);
      }
    });

    socket.on("showAnswer", (response) => {
      setSongs([]);
      setResult(response.answer);
      const p_data = response.players.find((p) => p.pid === socket.id);
      setPresult(p_data);
      setPcount(response.players.filter((p) => p.correct).length);
      setMode("result");
    });

    socket.on("endgame", (data) => {
      if (data.success) {
        setWinners(data.winners);
        setMode("endgame");
      }
    });

    socket.on("kicked", (data) => {
      socket.emit("leaveLobby");
      alert(data.message);
    });



    // Cleanup-Funktion
    return () => {
      socket.emit("leaveLobby"); // Beim Verlassen der Komponente aus der Lobby austreten
      socket.off("playerUpdate");
      socket.off("roundUpdate");
      socket.off("chatUpdate");
      socket.off("showAnswer");
      socket.off("game");
      socket.off("endgame");
      socket.off("kicked");
    };
  }, [Navigate]);

  const createRoom = () => {
    socket.emit("createRoom", (response) => {
      if (response.success) {
        setLobbyCode(response.lobbyCode);
        setPlayers(response.players);
        setName(response.playerName);
        setAdmin(true);
        setMode("lobby");
      }
    });
  };

  const joinBtn = (code) => {
    socket.emit("joinRoom", { lobbyCode: code }, (response) => {
      if (response.success) {
        setPlayers(response.players);
        setName(response.playerName);
        setLobbyCode(code);
        setMode("lobby");
      } else {
        console.log(response.error);
      }
    });
  };

  const sendmsg = (message) => {
    socket.emit("sendChat", { msg: message }, (response) => {
      if (!response.success) {
        console.log(response.error);
      }
    });
  };

  const changeName = (name) => {
    socket.emit("changeName", { name }, (response) => {
      if (response.success) {
        setName(response.name);
      } else {
        console.log(response.error);
      }
    });
  };

  const changeRound = (round) => {
    socket.emit("changeRound", { round }, (response) => {
      if (response.success) {
        console.log("Neu Runden")
      } else {
        console.log(response.error);
      }
    });
  };


  const startGame = () => {
    socket.emit("startGame", (response) => {
      if (!response.success) {
        console.log(response.error);
      }
    });
  };

  const PlayMp3 = () => {
    const aPlayer = document.getElementById("media");
    if (aPlayer) {
      aPlayer.volume = volume; // Ensure the volume is set before playing
      aPlayer.play();
    }
  };

  const StopMp3 = () => {
    const aPlayer = document.getElementById("media");
    if (aPlayer) {
      aPlayer.pause();
      aPlayer.currentTime = 0;
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);

    const aPlayer = document.getElementById("media");
    if (aPlayer) {
      aPlayer.volume = newVolume;
    }
  };

  const click = (name) => {
    StopMp3();
    setMode("wating");
    socket.emit("submitAnswer", name);
  };

  const startNewGame = () => {
    // Logic to start a new game
    setMode("select");
    // Additional logic to reset the game state
  };

  if (mode === "select") {
    return <MP_Select createRoom={createRoom} joinBtn={joinBtn} />;
  }

  return (
    <div className="container-fluid middle">
      <div className="row">
        <div className="col-md-3 custom-col">
          <div className="card card-dark color-darkblue text-white mt-3">
            <div className="card-body">
              <h5 className="card-title">Chat</h5>
              <Chat messages={messages} sendmsg={sendmsg} name={name} />
            </div>
          </div>
        </div>
        <div className="col-md-7 custom-col">
          <div className="card card-dark color-darkblue text-white mt-3">
            <div className="card-body">
              {["play", "result", "wating"].includes(mode) && (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <p>Runde: {round.round} / {round.maxRound}</p>
                  <div>
                    <label htmlFor="volumeSlider" style={{ marginRight: "10px" }}>Lautstärke:</label>
                    <input
                      id="volumeSlider"
                      type="range"
                      min="0"
                      max="0.30"
                      step="0.01"
                      value={volume}
                      className="slider-SQ"
                      onChange={handleVolumeChange}
                    />
                  </div>
                </div>
              )}
              {mode === "lobby" && <MP_Lobby lobbyCode={lobbyCode} changeName={changeName} name={name} startGame={startGame} isAdmin={isAdmin} Lround={round.maxRound} changeRound={changeRound} />}
              {mode === "play" && <MP_Game data={songs} PlayMp3={PlayMp3} click={click} volume={volume} />}
              {mode === "result" && <MP_Result player={Presult} answer={result} pcount={Pcount} />}
              {mode === "endgame" && <MP_Final winners={winners} startNewGame={startNewGame} />}
              {mode === "wating" && (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p>Warte auf die anderen Spieler</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col custom-col">
          <div className="card card-dark color-darkblue text-white mt-3">
            <div className="card-body">
              <Leaderboard players={players} currentPlayerName={name} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MP_Index;
