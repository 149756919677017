import React, { useState, useEffect } from "react";

function MP_Game({ data, PlayMp3, click, volume }) {
  const [info, setInfo] = useState('Klick mich um zu Starten!');

  useEffect(() => {
    // Set the volume whenever the component mounts or a new song is loaded
    const aPlayer = document.getElementById("media");
    if (aPlayer) {
      aPlayer.volume = volume; // Set initial volume
    }

    const timeoutId = setTimeout(() => {
      setInfo('Wie heißt der Song?')
    }, 3000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, [data.answer.url, volume]); // Effect runs when song URL or volume changes

  return (
    <div>
      <audio id="media" autoPlay={true} src={data.answer.url}></audio>
      <div className="row mt-5">
        <div onClick={() => { PlayMp3() }} data-toggle="tooltip" data-placement="top" title="Klick mich um den Song zuhören!" className="clicker mb-5 w-md-25 w-lg-25 w-xl-25 w-sm-50 mx-auto text-white mt-4 text-center btn div-SQ color-lightblue">
          {info}
        </div>
      </div>
      <div className="row justify-content-around">
        <div onClick={() => click(data.scope[0].name)} className="col-lg-4 col-md-4 col-xs-2 mb-5  w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue text-white d-flex btn btn-large div-SQ">
          <div className="text-center">
            <span className="badge color-darkblue mr-3 game">1</span>
            {data.scope[0].name}
          </div>
        </div>
        <div onClick={() => click(data.scope[1].name)} className="col-lg-4 col-md-4 offset-md-1 col-xs-2  mb-5 col-xs w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue text-center text-white d-flex btn btn-large div-SQ">
          <div className="text-center">
            <span className="badge color-darkblue mr-3 game">2</span>
            {data.scope[1].name}
          </div>
        </div>
        <div onClick={() => click(data.scope[2].name)} className="col-lg-4 col-md-4 col-xs-2 mb-5  w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue  text-white  clicker d-flex btn btn-large div-SQ">
          <div className="text-center">
            <span className="badge color-darkblue mr-3 game">3</span>
            {data.scope[2].name}
          </div>
        </div>
        <div onClick={() => click(data.scope[3].name)} className="col-lg-4 col-md-4 offset-md-1 col-xs-2  mb-5 col-xs w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue text-center text-white d-flex btn btn-large div-SQ">
          <div className="text-center">
            <span className="badge color-darkblue mr-3 game">4</span>
            {data.scope[3].name}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MP_Game;
