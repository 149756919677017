import React from "react";

function MP_Result({ player, answer, pcount }) {


    return (
        <div className="card-body">
            <div className="text-center">
                <h5 className="text-center p-2">Dein Antwort war {player.correct ? "Richtig!" : "Falsch!"}</h5>
                <div className="text-center">Deine Antwort war <u> {player.answer}</u></div>
                <div className="text-center">Der Song hieß <u>{answer.correctSongName}</u></div>
                <div className="text-center">Der Song ist von <u>{answer.correctSongArtist}</u></div>
                <div className="text-center mt-4">
                    Du hast <u>{player.points}</u> Punkte erhalten.
                </div>
                <div className="text-center">
                    {pcount} Spieler hatten die Antwort richtig!
                </div>
                <div className="text-center mt-4">
                    Weiter so!
                </div>
            </div>
        </div>
    )

}


export default MP_Result;