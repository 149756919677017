import React from "react";

function MP_Final({ winners, startNewGame }) {
    // Sort winners by score in descending order and take the top 3
    const topWinners = winners.sort((a, b) => b.score - a.score).slice(0, 3);

    return (   
        <div className="card-body">
            <div className="text-center">
                <h2 className="text-center p-2">Das Spiel ist vorbei!</h2>
                <h4 className="text-center">Die Top 3 Gewinner sind:</h4>
                <div className="winners-container">
                    {topWinners.map((winner, index) => (
                        <div key={index} className={`winner color-lightblue winner-${index + 1}`}>
                            <div className="winner-rank">#{index + 1}</div>
                            <div className="winner-name">{winner.name}</div>
                            <div className="winner-score">Score: {winner.score}</div>
                        </div>
                    ))}
                </div>
                <div className="text-center mt-4">
                    <h5>Glückwunsch an alle Gewinner!</h5>
                    <button className="btn text-white color-lightblue mt-3" onClick={startNewGame}>Neues Spiel starten</button>
                </div>
            </div>
        </div>
    );
}

export default MP_Final;