import React, { useState, useEffect, useRef } from "react";

function Chat({ messages, sendmsg, name }) {
    const [values, setValues] = useState({ msg: "" }); // State to store input
    const [suggestions, setSuggestions] = useState([]); // State for command suggestions
    const chatContainerRef = useRef(null);

    const commands = ["/skip", "/clear"]; // Define available commands

    useEffect(() => {
        // Scroll to the bottom of the chat container whenever messages change
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, [messages]);

    const set = (name) => {
        return ({ target: { value } }) => {
            setValues((oldValues) => ({ ...oldValues, [name]: value }));

            // Show suggestions if input starts with "/"
            if (value.startsWith("/")) {
                const input = value.toLowerCase();
                setSuggestions(
                    commands.filter((cmd) => cmd.toLowerCase().startsWith(input))
                );
            } else {
                setSuggestions([]);
            }
        };
    };

    const send = (msg) => {
        if (msg.length !== 0) {
            sendmsg(msg);
            setValues({ msg: "" });
            setSuggestions([]);
        }
    };

    const selectSuggestion = (suggestion) => {
        setValues({ msg: suggestion });
        setSuggestions([]); // Hide suggestions once a command is selected
    };

    return (
        <div className="chat-container" style={{ maxHeight: "400px", minHeight: "400px", overflowY: "auto" }} ref={chatContainerRef}>
            <div className="chat-messages">
                {messages.slice(-4).map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.sender === name ? "local" : "players"} color-lightblue`}
                    >
                        <span className="sender">{message.sender}</span>
                        <p className="text">{message.content}</p>
                    </div>
                ))}
            </div>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    send(values.msg);
                }}
                className="row mt-3 mx-auto"
            >
                <input
                    className="input-SQ rounded form-control col-md-8"
                    type="text"
                    placeholder="Nachricht eingeben..."
                    onChange={set("msg")}
                    value={values.msg}
                />
                <button
                    onClick={() => send(values.msg)}
                    className="btn color-lightblue text-white col-md-3 ml-2"
                    type="button"
                >
                    Send
                </button>
            </form>
            {suggestions.length > 0 && (
                <ul className="suggestions-list" style={{ listStyleType: "none", padding: 0, marginTop: "10px" }}>
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => selectSuggestion(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Chat;
