import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { socket } from "./functions/fnc_socket";

// Routes
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Changelog from "./pages/other/changelog";
import Home from "./pages/home";
import Job from "./pages/job";
import Impressum from "./pages/other/impressum";
import SinglePlayer from "./pages/game/singleplayer";
import Proposals from "./pages/other/proposals";
import MP_Index from "./pages/game/mulitplayer";
import Stats from './pages/other/stats';

const root = createRoot(document.getElementById('root'));

const App = () => {
  useEffect(() => {
    socket.onopen = () => {
      console.log('Socket connection established');
      // Any logic you want to perform when the socket connection is opened
    };

    return () => {
      socket.onopen = null; // Clean up socket on unmount or re-render
    };
  }, []);

  return (
    <React.StrictMode>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/changelog' element={<Changelog />} />
          <Route path='/jobs' element={<Job />} />
          <Route path='/impressum' element={<Impressum />} />
          <Route path='/proposals' element={<Proposals />} />
          <Route path='/stats' element={<Stats />} />

          <Route path='/game/single' element={<SinglePlayer />} />
          <Route path='/game/online' element={<MP_Index />} />
          <Route path='/game/online/:code' element={<MP_Index />} />
        </Routes>
        <Footer />
      </Router>
    </React.StrictMode>
  );
};

root.render(<App />);
