import React, { useState } from "react";

function MP_Select({ createRoom, joinBtn }) {
    const [values, setValues] = useState({ code: '' }); // State to store lobby code input


    //set data 
    const set = name => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [name]: value }));
            //console.log(value)
        }
    };




    return (
        <div>
            <div className="card card-dark color-darkblue text-white middle w-75  mx-auto">
                <div className="card-body container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                {/* Haupt-Card */}
                                <div className="card card-dark color-lightblue text-white text-center mt-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Erstelle eine Lobby</h5>
                                        <p className="text-muted">Erstelle hier eine Lobby und Spiele mit bis zu 8 Freunden zusammen.</p>
                                        <button onClick={createRoom} className="btn text-white color-darkblue">Erstellen!</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/* Zusätzliche Card innerhalb der Haupt-Card */}
                                <div className="card card-dark color-lightblue text-white text-center mt-3">
                                    <div className="card-body">
                                        <h5 className="card-title">Tritt einer Lobby bei</h5>
                                        <input
                                            className="input-SQ rounded form-control row mt-3 w-75 mx-auto"
                                            type="text"
                                            name="mpcode"
                                            placeholder="Lobby Code"
                                            onChange={set('code')}
                                            value={values.code}
                                        />
                                        <p></p>
                                        <button onClick={() => joinBtn(values.code)} className="btn text-white color-darkblue">Beitreten!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MP_Select;
