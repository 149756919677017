import React from "react"
import { Link } from "react-router-dom"
import CookieConsent from "react-cookie-consent";


function Footer() {

    return (<div >
        <CookieConsent overlay location="top" containerClasses="color-darkblue">Wir nutzen Cookies um dir ein besseres Erlebnis zuliefern.</CookieConsent>

        <div className="footer fixed-bottom color-darkblue p-2">
            <div className="container-fluid text-center ">
                <div className="row justify-content-center">
                    <Link className=" text-white col-6 col-xl col-lg col-md   col-order-10" to="/proposals">Fehler & Wünsche</Link>
                    <Link className=" text-white col-6 col-xl col-lg col-md col-order-10" to="/jobs">Jobs</Link>
                    <div className=" text-white col-xl-6 col-lg-6 col-md-6  col-10 d-none d-sm-block">© 2020-2025 Song-quiz.de </div>
                    <Link className=" text-white col-6 col-xl col-lg col-md col-order-10" to="/impressum">Impressum</Link>
                    <a className=" text-white col-6 col-xl col-lg col-md col-order-10" rel="noreferrer" href="https://github.com/Maurice037">Github</a>
                </div>
            </div>
        </div>
    </div>)

}

export default Footer;